import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { blue, red, green } from '@mui/material/colors';

const theme = createTheme();

const gridContainer = {
	height: theme.spacing(20)
};
const gridItem = {
	justifyContent: 'center',
	padding: theme.spacing(7.5)
};

const LandingPage = (): JSX.Element => {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container sx={gridContainer}>
				<Grid item xs={12}>
					<Stack direction="row" spacing={2} sx={gridItem}>
						<Avatar sx={{ bgcolor: red[500] }}>W</Avatar>
						<Avatar sx={{ bgcolor: blue[500] }}>I</Avatar>
						<Avatar sx={{ bgcolor: green[500] }}>P</Avatar>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default LandingPage;
