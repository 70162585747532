import './App.css';
import LandingPage from "./views/LandingPage/LandingPage"

const App = () => {
  return (
    <LandingPage />
  );
}

export default App;
